<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col>
          <h5 class="text-h5 text-typo font-weight-bold mb-0">
            Pallet Contact
          </h5>
          <div class="d-flex">
            <p class="text-sm text-body mb-0">
              Find and follow us on the Socials or Contact us directly. For
              Music &amp; Event booking inquiries, please email us at
              <a href="mailto:beer@thepallet.bar" style="color: #fdb826">
                beer@thepallet.bar </a
              >.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" md="4" cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        ORDER ONLINE
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col xs="12" md="4" cols="12">
          <v-card
            to="pallet-perks"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        PALLET PERKS LOYALTY
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col xs="12" md="4" cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet/marketing-signup"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        EMAIL SIGNUP
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="8" cols="12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3124.693232714862!2d-81.9417090846628!3d38.44855417964245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8848b574f82f6b81%3A0x6e99fb88391f3d24!2sThe%20Pallet%20Bar!5e0!3m2!1sen!2sus!4v1648317770620!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-col>
        <v-col xs="12" sm="4" cols="12">
          <v-row>
            <v-col cols="6">
              <v-card class="card-shadow pa-4 border-radius-md index-2">
                <a
                  href="https://www.facebook.com/thepalletbarwv"
                  target="_blank"
                >
                  <img src="@/assets/img/facebook.png" width="100%" />
                </a>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="card-shadow pa-4 border-radius-md z-index-2">
                <a
                  href="https://www.instagram.com/thepallet.bar"
                  target="_blank"
                >
                  <img src="@/assets/img/instagram.png" width="100%" />
                </a>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card class="card-shadow pa-4 border-radius-md index-2">
                <a href="tel:6812357339" target="_blank">
                  <img src="@/assets/img/phone.png" width="100%" />
                </a>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="card-shadow pa-4 border-radius-md index-2">
                <a href="mailto:beer@thepallet.bar" target="_blank">
                  <img src="@/assets/img/email.png" width="100%" />
                </a>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
